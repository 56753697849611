import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import TextField from "../../components/common/form/text-field/text-field"
import TextArea from "../../components/common/form/text-area/text-area"
import Button from "../../components/common/button/button"
import { useForm } from "react-form"
import styles from "./index.module.scss"
import { MdRoom, MdEmail } from "react-icons/md"

const ContactPage = () => {
  const { Form, meta, pushFieldValue } = useForm({
    debugForm: false,
    onSubmit: values => {
      var subject = values.subject
      var body = "Name: " + values.name + "\n"
      body += values.message
      var email = values.email
      var location =
        "mailto:" +
        "fhaa@albertaforesthistory.ca" +
        "?subject=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(body)
      if (typeof window !== undefined) {
      window.location.href = location
      }
    },
  })

  return (
    <WebsiteLayout>
      <SEO title="Contact" />
      <div className={styles.wrapper}>
        <div className={styles.heroImg}></div>
        <div className={styles.container}>
          <div className={styles.info}>
            <h4>Forest History Association of Alberta</h4>
            <p>
              <span>
                <MdRoom />
              </span>
              P.O. Box 48099<br />
              Village Landing Post Office<br />
              St. Albert, AB T8N 5V9
              {/* 22 Hutchinson Place St. <br /> Albert, AB T8N 6R3 */}
            </p>
            <p>
              <span>
                <MdEmail />
              </span>
              fhaa@albertaforesthistory.ca
            </p>
          </div>
          <div className={styles.form}>
            <Form>
              <h3>Get in touch with us</h3>
              <TextField label="Name" field="name" type="text" />
              <TextField label="Subject" field="subject" type="text" />
              <TextArea label="Message" field="message" />
              <div className={styles.action}></div>
              <Button
                id="contactSubmit"
                type="submit"
                label="Send"
                btnSize="large"
                fullWidth
              />
            </Form>
          </div>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default ContactPage
